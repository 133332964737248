// store/useNotesStore.js
import { create } from 'zustand';
import axios from '../axiosConfig';

const useNotesStore = create((set, get) => ({
  // State
  notes: [],
  status: 'idle',
  error: null,
  currentPage: 1,
  totalPages: 1,
  currentNote: null,
  currentNoteStatus: 'idle',
  currentNoteError: null,
  allLinks: [],
  linksStatus: 'idle',
  linksError: null,
  linksPagination: {
    currentPage: 1,
    totalPages: 1,
    totalLinks: 0
  },

  // Actions
  clearCurrentNote: () => set({
    currentNote: null,
    currentNoteStatus: 'idle',
    currentNoteError: null
  }),

  // Fetch notes
  fetchNotes: async ({ page = 1, tag = null, bookmarked = false, shouldAppend = false }) => {
    set({ status: 'loading' });
    try {
      let url = `/api/notes?page=${page}`;
      if (tag) {
        url += `&tag=${encodeURIComponent(tag)}`;
      }
      if (bookmarked) {
        url += '&bookmarked=true';
      }
      const response = await axios.get(url);
      const data = response.data;
      
      set(state => ({
        status: 'succeeded',
        notes: shouldAppend ? [...state.notes, ...data.notes] : data.notes,
        currentPage: data.currentPage,
        totalPages: data.totalPages
      }));
      return data;
    } catch (error) {
      set({ 
        status: 'failed', 
        error: error.response?.data || error.message || 'An error occurred while fetching notes' 
      });
      throw error;
    }
  },

  // Fetch note by ID
  fetchNoteById: async (id) => {
    set({ currentNoteStatus: 'loading' });
    try {
      const response = await axios.get(`/api/notes/${id}`);
      const fetchedNote = response.data;
      
      set(state => {
        // Update the note in the list if it exists
        const noteIndex = state.notes.findIndex(note => note._id === fetchedNote._id);
        const updatedNotes = [...state.notes];
        if (noteIndex !== -1) {
          updatedNotes[noteIndex] = fetchedNote;
        }
        
        return {
          currentNoteStatus: 'succeeded',
          currentNote: fetchedNote,
          notes: updatedNotes
        };
      });
      
      return response.data;
    } catch (error) {
      set({ 
        currentNoteStatus: 'failed', 
        currentNoteError: error.response?.data || error.message || 'An error occurred while fetching the note' 
      });
      throw error;
    }
  },

  // Add new note
  addNote: async ({ content, autoTags, removedAutoTags }) => {
    try {
      const response = await axios.post('/api/notes', { content, autoTags, removedAutoTags });
      const newNote = response.data;
      
      set(state => ({
        notes: [newNote, ...state.notes]
      }));
      
      return newNote;
    } catch (error) {
      set({ error: error.response?.data || error.message || 'An error occurred while adding the note' });
      throw error;
    }
  },

  // Update note
  updateNote: async ({ id, content, tags }) => {
    try {
      const response = await axios.put(`/api/notes/${id}`, { content, tags });
      const updatedNote = response.data;
      
      set(state => {
        const updatedNotes = [...state.notes];
        const noteIndex = updatedNotes.findIndex(note => note._id === id);
        
        if (noteIndex !== -1) {
          updatedNotes[noteIndex] = updatedNote;
        }
        
        return {
          notes: updatedNotes,
          currentNote: state.currentNote && state.currentNote._id === id ? updatedNote : state.currentNote
        };
      });
      
      return updatedNote;
    } catch (error) {
      set({ error: error.response?.data || error.message || 'An error occurred while updating the note' });
      throw error;
    }
  },

  // Update note optimistically
  updateNoteOptimistic: (updatedNote) => {
    set(state => {
      const updatedNotes = [...state.notes];
      const noteIndex = updatedNotes.findIndex(note => note._id === updatedNote._id);
      
      if (noteIndex !== -1) {
        updatedNotes[noteIndex] = updatedNote;
      }
      
      return {
        notes: updatedNotes
      };
    });
  },

  // Delete note
  deleteNote: async (id) => {
    try {
      await axios.delete(`/api/notes/${id}`);
      
      set(state => ({
        notes: state.notes.filter(note => note._id !== id),
        currentNote: state.currentNote && state.currentNote._id === id ? null : state.currentNote
      }));
      
      return id;
    } catch (error) {
      set({ error: error.response?.data || error.message || 'An error occurred while deleting the note' });
      throw error;
    }
  },

  // Delete note optimistically
  deleteNoteOptimistic: (id) => {
    set(state => ({
      notes: state.notes.filter(note => note._id !== id)
    }));
  },

  // Toggle note lock
  toggleNoteLock: async (id) => {
    try {
      const response = await axios.patch(`/api/notes/${id}/toggle-lock`);
      const updatedNote = response.data;
      
      set(state => {
        const updatedNotes = [...state.notes];
        const noteIndex = updatedNotes.findIndex(note => note._id === id);
        
        if (noteIndex !== -1) {
          updatedNotes[noteIndex] = {
            ...updatedNotes[noteIndex],
            isLocked: updatedNote.isLocked
          };
        }
        
        return {
          notes: updatedNotes,
          currentNote: state.currentNote && state.currentNote._id === id ? 
            { ...state.currentNote, isLocked: updatedNote.isLocked } : 
            state.currentNote
        };
      });
      
      return updatedNote;
    } catch (error) {
      set({ error: error.response?.data || error.message || 'An error occurred while toggling the note lock' });
      throw error;
    }
  },

  // Toggle note bookmark
  toggleNoteBookmark: async (id) => {
    try {
      const response = await axios.patch(`/api/notes/${id}/toggle-bookmark`);
      const updatedNote = response.data;
      
      set(state => {
        const updatedNotes = [...state.notes];
        const noteIndex = updatedNotes.findIndex(note => note._id === id);
        
        if (noteIndex !== -1) {
          updatedNotes[noteIndex] = {
            ...updatedNotes[noteIndex],
            isBookmarked: updatedNote.isBookmarked
          };
        }
        
        return {
          notes: updatedNotes,
          currentNote: state.currentNote && state.currentNote._id === id ? 
            { ...state.currentNote, isBookmarked: updatedNote.isBookmarked } : 
            state.currentNote
        };
      });
      
      return updatedNote;
    } catch (error) {
      set({ error: error.response?.data || error.message || 'An error occurred while toggling the note bookmark' });
      throw error;
    }
  },

  // Toggle note collapse
  toggleNoteCollapse: async ({ id, isCollapsed }) => {
    try {
      const response = await axios.patch(`/api/notes/${id}/toggle-collapse`, { isCollapsed });
      const updatedNote = response.data;
      
      set(state => {
        const updatedNotes = [...state.notes];
        const noteIndex = updatedNotes.findIndex(note => note._id === id);
        
        if (noteIndex !== -1) {
          updatedNotes[noteIndex] = {
            ...updatedNotes[noteIndex],
            isCollapsed: updatedNote.isCollapsed
          };
        }
        
        return {
          notes: updatedNotes,
          currentNote: state.currentNote && state.currentNote._id === id ? 
            { ...state.currentNote, isCollapsed: updatedNote.isCollapsed } : 
            state.currentNote
        };
      });
      
      return updatedNote;
    } catch (error) {
      set({ error: error.response?.data || error.message || 'An error occurred while toggling the note collapse' });
      throw error;
    }
  },

  // Toggle note collapse optimistically
  toggleNoteCollapseOptimistic: ({ id, isCollapsed }) => {
    set(state => {
      const updatedNotes = [...state.notes];
      const noteIndex = updatedNotes.findIndex(note => note._id === id);
      
      if (noteIndex !== -1) {
        updatedNotes[noteIndex] = {
          ...updatedNotes[noteIndex],
          isCollapsed
        };
      }
      
      return {
        notes: updatedNotes
      };
    });
  },

  // Add tag
  addTag: async ({ noteId, tag }) => {
    try {
      const response = await axios.put(`/api/notes/${noteId}`, { tags: [tag] });
      const updatedNote = response.data;
      
      set(state => {
        const updatedNotes = [...state.notes];
        const noteIndex = updatedNotes.findIndex(note => note._id === noteId);
        
        if (noteIndex !== -1) {
          updatedNotes[noteIndex] = updatedNote;
        }
        
        return {
          notes: updatedNotes,
          currentNote: state.currentNote && state.currentNote._id === noteId ? 
            updatedNote : 
            state.currentNote
        };
      });
      
      return updatedNote;
    } catch (error) {
      set({ error: error.response?.data || error.message || 'An error occurred while adding the tag' });
      throw error;
    }
  },

  // Add tag optimistically
  addTagOptimistic: ({ noteId, tag }) => {
    set(state => {
      const updatedNotes = [...state.notes];
      const noteIndex = updatedNotes.findIndex(note => note._id === noteId);
      
      if (noteIndex !== -1 && !updatedNotes[noteIndex].tags.includes(tag)) {
        updatedNotes[noteIndex] = {
          ...updatedNotes[noteIndex],
          tags: [...updatedNotes[noteIndex].tags, tag]
        };
      }
      
      return {
        notes: updatedNotes
      };
    });
  },

  // Remove tag
  removeTag: async ({ noteId, tag }) => {
    try {
      const response = await axios.put(`/api/notes/${noteId}`, { tags: [tag], removeTag: true });
      const updatedNote = response.data;
      
      set(state => {
        const updatedNotes = [...state.notes];
        const noteIndex = updatedNotes.findIndex(note => note._id === noteId);
        
        if (noteIndex !== -1) {
          updatedNotes[noteIndex] = updatedNote;
        }
        
        return {
          notes: updatedNotes,
          currentNote: state.currentNote && state.currentNote._id === noteId ? 
            updatedNote : 
            state.currentNote
        };
      });
      
      return updatedNote;
    } catch (error) {
      set({ error: error.response?.data || error.message || 'An error occurred while removing the tag' });
      throw error;
    }
  },

  // Remove tag optimistically
  removeTagOptimistic: ({ noteId, tag }) => {
    set(state => {
      const updatedNotes = [...state.notes];
      const noteIndex = updatedNotes.findIndex(note => note._id === noteId);
      
      if (noteIndex !== -1) {
        updatedNotes[noteIndex] = {
          ...updatedNotes[noteIndex],
          tags: updatedNotes[noteIndex].tags.filter(t => t !== tag)
        };
      }
      
      return {
        notes: updatedNotes
      };
    });
  },

  // Fetch all links
  fetchAllLinks: async ({ page = 1, limit = 100 }) => {
    console.log(`🔍 fetchAllLinks: Starting request for page ${page}, limit ${limit}`);
    set({ linksStatus: 'loading' });
    
    try {
      const url = `/api/all-links?page=${page}&limit=${limit}`;
      console.log(`📡 Sending request to: ${url}`);
      
      const response = await axios.get(url);
      console.log(`📦 Raw response:`, response);
      
      const data = response.data;
      console.log(`📋 Response data:`, {
        linksCount: data.links?.length || 0,
        currentPage: data.currentPage,
        totalPages: data.totalPages,
        totalLinks: data.totalLinks
      });
      
      // Validate the response
      if (!data.links || !Array.isArray(data.links)) {
        console.error('⚠️ Invalid response format - links is missing or not an array');
        throw new Error('Invalid response format from server');
      }
      
      // Check if the page is empty but shouldn't be
      if (data.links.length === 0 && page <= data.totalPages && page > 1) {
        console.warn(`⚠️ Received empty links array for page ${page}, but totalPages is ${data.totalPages}`);
      }
      
      // Detailed logging of the first few links
      if (data.links.length > 0) {
        console.log(`🔗 Sample link data:`, data.links.slice(0, 2));
      }
      
      set({
        linksStatus: 'succeeded',
        allLinks: data.links.map(link => ({
          ...link,
          count: link.count || 1,
          latestDate: link.latestDate ? new Date(link.latestDate).toISOString() : null,
          noteIds: link.noteIds || [] // Ensure noteIds exists
        })),
        linksPagination: {
          currentPage: data.currentPage || page,
          totalPages: data.totalPages || 1,
          totalLinks: data.totalLinks || data.links.length
        }
      });
      
      console.log(`✅ fetchAllLinks: Successfully loaded page ${page}`);
      return data;
    } catch (error) {
      console.error("❌ Link fetch error:", error);
      
      // Log detailed error information
      if (error.response) {
        console.error("📡 Server response:", {
          status: error.response.status,
          data: error.response.data,
          headers: error.response.headers
        });
      } else if (error.request) {
        console.error("📡 No response received:", error.request);
      }
      
      set({ 
        linksStatus: 'failed', 
        linksError: error.response?.data?.message || error.message || 'An error occurred while fetching links',
      });
      throw error;
    }
  },

  // Remove metadata from a note
  removeMetadata: async ({ noteId, url }) => {
    try {
      const response = await axios.patch(`/api/notes/${noteId}/remove-metadata`, { url });
      const updatedNote = response.data;
      
      set(state => {
        const updatedNotes = [...state.notes];
        const noteIndex = updatedNotes.findIndex(note => note._id === noteId);
        
        if (noteIndex !== -1) {
          updatedNotes[noteIndex] = updatedNote;
        }
        
        return {
          notes: updatedNotes,
          currentNote: state.currentNote && state.currentNote._id === noteId ? 
            updatedNote : 
            state.currentNote
        };
      });
      
      return updatedNote;
    } catch (error) {
      set({ error: error.response?.data || error.message || 'An error occurred while removing metadata' });
      throw error;
    }
  },


// Toggle metadata collapse
  toggleMetadataCollapse: async (id) => {
    try {
      const response = await axios.patch(`/api/notes/${id}/toggle-metadata-collapse`);
      const updatedNote = response.data;
      
      set(state => {
        const updatedNotes = [...state.notes];
        const noteIndex = updatedNotes.findIndex(note => note._id === id);
        
        if (noteIndex !== -1) {
          updatedNotes[noteIndex] = {
            ...updatedNotes[noteIndex],
            isMetadataCollapsed: updatedNote.isMetadataCollapsed
          };
        }
        
        return {
          notes: updatedNotes,
          currentNote: state.currentNote && state.currentNote._id === id ? 
            { ...state.currentNote, isMetadataCollapsed: updatedNote.isMetadataCollapsed } : 
            state.currentNote
        };
      });
      
      return updatedNote;
    } catch (error) {
      set({ error: error.response?.data || error.message || 'An error occurred while toggling metadata collapse' });
      throw error;
    }
  },

  // Toggle metadata collapse optimistically
  toggleMetadataCollapseOptimistic: (id) => {
    set(state => {
      const updatedNotes = [...state.notes];
      const noteIndex = updatedNotes.findIndex(note => note._id === id);
      
      if (noteIndex !== -1) {
        updatedNotes[noteIndex] = {
          ...updatedNotes[noteIndex],
          isMetadataCollapsed: !updatedNotes[noteIndex].isMetadataCollapsed
        };
      }
      
      return {
        notes: updatedNotes
      };
    });
  },

  
  // Optimistic removal of metadata
  removeMetadataOptimistic: ({ noteId, url }) => {
    set(state => {
      const updatedNotes = [...state.notes];
      const noteIndex = updatedNotes.findIndex(note => note._id === noteId);
      
      if (noteIndex !== -1) {
        updatedNotes[noteIndex] = {
          ...updatedNotes[noteIndex],
          linkMetadata: updatedNotes[noteIndex].linkMetadata.filter(meta => meta.url !== url)
        };
      }
      
      return {
        notes: updatedNotes
      };
    });
  }
}));

export default useNotesStore;