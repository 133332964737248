import React, { Suspense, lazy, memo, useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, Link } from 'react-router-dom';
import ErrorBoundary from './ErrorBoundary';
import PasscodeEntry from './PasscodeEntry';
import LoadingBar from './components/LoadingBar';
import NetworkStatus from './components/NetworkStatus'; // New import
import useNotesStore from './store/useNotesStore';

import './normalize.css';
import './reset.css';
import './App.css';

const NoteList = lazy(() => import('./NoteList'));
const NoteView = lazy(() => import('./NoteView'));
const BookmarkedNotes = lazy(() => import('./BookmarkedNotes'));
const LinksList = lazy(() => import('./components/LinksList'));
const CacheManager = lazy(() => import('./components/CacheManager'));

const LoadingFallback = memo(() => (
  <div role="status" aria-live="polite">Loading...</div>
));

const Header = memo(() => {
  const refreshApp = React.useCallback(() => {
    window.location.reload();
  }, []);

  return (
    <div className="app-header">
      <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}><h1>Notes App</h1></Link>
      <Link to="/bookmarks" style={{ textDecoration: 'none', color: 'inherit' }}>Bookmarks</Link>
      <Link to="/links" style={{ textDecoration: 'none', color: 'inherit' }}>All Links</Link>
      <Link to="/cache" style={{ textDecoration: 'none', color: 'inherit' }}>Cache Management</Link>
      <NetworkStatus /> {/* Add NetworkStatus here */}
      <button onClick={refreshApp} className="refresh-button" aria-label="Refresh app">
        <img src="img/icons/icons8-refresh-120.png" alt="Refresh" className="refresh-icon" />
      </button>
    </div>
  );
});

function App() {
  const isLoading = useNotesStore(state => state.status === 'loading');
  const [isVerified, setIsVerified] = useState(false);

  useEffect(() => {
    const storedSession = localStorage.getItem('notesAppSession');
    if (storedSession === 'true') {
      setIsVerified(true);
    }
  }, []);

  if (!isVerified) {
    return <PasscodeEntry onPasscodeVerified={() => setIsVerified(true)} />;
  }

  return (
    <Router>
      <ErrorBoundary>
        <div className="app-container">
          {isLoading && <LoadingBar />}
          <Header />
          <Suspense fallback={<LoadingFallback />}>
            <Routes>
              <Route path="/" element={<NoteList />} />
              <Route path="/page/:page" element={<NoteList />} />
              <Route path="/tag/:tag" element={<NoteList />} />
              <Route path="/tag/:tag/page/:page" element={<NoteList />} />
              <Route path="/note/:id" element={<NoteView />} />
              <Route path="/bookmarks" element={<BookmarkedNotes />} />
              <Route path="/links" element={<LinksList />} />
              <Route path="/cache" element={<CacheManager />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </Suspense>
        </div>
      </ErrorBoundary>
    </Router>
  );
}

export default memo(App);