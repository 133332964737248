import axios from 'axios';
import { SERVER_URL } from './config';

// Store for network status
const networkStatus = {
  statuses: [], // Array of { status: number, timestamp: Date }
  maxEntries: 10, // Limit to last 10 requests
  addStatus(status) {
    this.statuses.unshift({ status, timestamp: new Date() });
    if (this.statuses.length > this.maxEntries) {
      this.statuses.pop();
    }
  },
  getLatestStatus() {
    return this.statuses[0]?.status || 200; // Default to 200 if no requests yet
  },
  hasErrors() {
    return this.statuses.some(s => s.status >= 400);
  }
};

const instance = axios.create({
  baseURL: SERVER_URL,
});

// Request interceptor
instance.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    networkStatus.addStatus(error.response?.status || 500); // Assume 500 if no response
    return Promise.reject(error);
  }
);

// Response interceptor
instance.interceptors.response.use(
  (response) => {
    networkStatus.addStatus(response.status);
    return response;
  },
  (error) => {
    let errorMessage = 'An unexpected error occurred';
    let status = 500; // Default to server error

    if (error.response) {
      status = error.response.status;
      switch (status) {
        case 400:
          errorMessage = 'Bad request. Please check your input.';
          break;
        case 401:
          errorMessage = 'Unauthorized. Please log in.';
          break;
        case 403:
          errorMessage = 'Forbidden. You don\'t have permission to access this resource.';
          break;
        case 404:
          errorMessage = 'Resource not found.';
          break;
        case 500:
          errorMessage = 'Internal server error. Please try again later.';
          break;
        default:
          errorMessage = `Server responded with error: ${error.response.data.message || error.response.statusText}`;
      }
    } else if (error.request) {
      errorMessage = 'No response received from server. Please check your internet connection.';
      status = 503; // Service unavailable
    }

    networkStatus.addStatus(status);
    return Promise.reject(new Error(errorMessage));
  }
);

// Export the network status along with the axios instance
export { instance as default, networkStatus };