import React, { useState, useEffect } from 'react';
import { networkStatus } from '../axiosConfig';

const NetworkStatus = () => {
  const [status, setStatus] = useState(networkStatus.getLatestStatus());
  const [hasErrors, setHasErrors] = useState(networkStatus.hasErrors());

  useEffect(() => {
    // Poll network status every second to update UI
    const interval = setInterval(() => {
      setStatus(networkStatus.getLatestStatus());
      setHasErrors(networkStatus.hasErrors());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const getIconStyle = () => {
    if (hasErrors) {
      return { backgroundColor: 'var(--delete-color)' }; // Red for 4xx/5xx
    }
    return { backgroundColor: 'var(--confirm-color)' }; // Green for 200
  };

  return (
    <div className="network-status" title={`Latest HTTP Status: ${status}`}>
      <span className="status-icon " style={getIconStyle()}></span>
      <span className="status-code">
        {hasErrors ? (status >= 500 ? '5xx' : '4xx') : 'All Good'}
      </span>
    </div>
  );
};

export default NetworkStatus;



